import React from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import contactData from "../data/data.json";

const Styles = styled.div`
  footer {
    background-color: #f4f4f4;
  }

  a {
    color: #5f6368;

    &:hover {
      color: #333;
    }
  }

  li {
    margin-bottom: 1rem;
  }

  .social-icon {
    color: #222;
    font-size: 28px;
    margin: 0 0.5rem;
    vertical-align: middle;

    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
`;

const Footer = () => (
  <Styles>
    <footer className="footer py-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="lead">
              <span className="mr-3">Contactanos en:</span>
              
              <a
                href={"mailto:"+contactData.contact.email+"?subject=Consulta&body=Hola%2C%0D%0Ame%20contactaba%20para%20hacer%20una%20consulta"}
                className="float"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="social-icon"
                  icon={["fa", "envelope"]}
                  size="2x"
                />
              </a>
              <a
                href={"callto:"+contactData.contact.number}
                className="float"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="social-icon"
                  icon={["fa", "phone"]}
                />
                </a>

              <a
                href={"https://www.instagram.com/"+contactData.contact.instagram}
                className="float"
                target="_blank"
              >
                {/*Innova Aberturas y Estructuras*/}
                {/*https://www.linkedin.com/in/mariabelenscassibragas/#*/}
                <FontAwesomeIcon
                  className="social-icon"
                  icon={["fab", "instagram"]}
                />
              </a>
            </p>
          </div>
        </div>
        <hr className="mt-3 mb-5" />
        <div className="row">
          {/*
          <div className="col-12 col-md-4 text-center text-md-left mb-4 mb-md-0">
            <h5>More about us</h5>
            <ul className="navbar-nav mt-4">
              <li className="nav-item">
                <Link to="/">Contact Us</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Investor Relations</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Careers</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Locations</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Blog</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 text-center text-md-left mb-4 mb-md-0">
            <h5>Press room</h5>
            <ul className="navbar-nav mt-4">
              <li className="nav-item">
                <Link to="/">Press Enquiries</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Social Media Directories</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Images & B-roll</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Permissions</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Speaker Requests</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 text-center text-md-left mb-4 mb-md-0">
            <h5>Policy</h5>
            <ul className="navbar-nav mt-4">
              <li className="nav-item">
                <Link to="/">Application Security</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Software Principles</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Unwanted Software Policy</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Responsible Supply Chain</Link>
              </li>
              <li className="nav-item">
                <Link to="/">Conflict Minerals Policy</Link>
              </li>
            </ul>
          </div>
           */}
        </div>
      </div>
    </footer>
  </Styles>
);

export default Footer;
