import React from 'react';
import _uniqueId from 'lodash/uniqueId';
var axios = require('axios');
var FormData = require('form-data');

import "./styles/styles.css"

export default class FormJS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'Please write an essay about your favorite DOM element.',
      ingrese: "Por favor ingrese su ",
      require: "Se requiere un ",
      name: "",
      surname: '',
      email: '',
      phone: '',
      message: '',

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSurname = this.handleChangeSurname.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  handleChangeName(event) {
    this.setState({ name: event.target.value });
    console.log(this.state.name)
  }
  handleChangeSurname(event) {
    this.setState({ surname: event.target.value });
    console.log(this.state.surname)
  }
  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
    console.log(this.state.email)
  }
  handleChangePhone(event) {
    this.setState({ phone: event.target.value });
    console.log(this.state.phone)
  }
  handleChangeMessage(event) {
    this.setState({ message: event.target.value });
    console.log(this.state.message)
  }


  sendEmail() {
    var data = new FormData();
    data.append('name', this.state.name);
    data.append('surname', this.state.surname);
    data.append('email', this.state.email);
    data.append('phone', this.state.phone);
    data.append('message', this.state.message);

    var config = {
      method: 'post',
      url: 'https://api-send-email-sd.herokuapp.com',
      headers: {},
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  handleSubmit(event) {
    event.preventDefault();

    console.log("name: " + this.state.name);
    console.log("surname: " + this.state.surname);
    console.log("email: " + this.state.email);

    console.log('An essay was submitted: ' + this.state.value);
    this.sendEmail()
  }
  // handler recieves the `e` event object
  formPreventDefault(e) {
    console.log('here');
    e.preventDefault();
  }

  render() {
    return (

      <div className="container">
        <div className="row pt-5 pb-5">

          <div className="col-lg-8 col-lg-offset-2">


            <form id="contact-form" onSubmit={this.formPreventDefault}>

              <div className="messages"></div>

              <div className="controls">

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="form_name">Nombre *</label>
                      <input id="form_name" type="text" name="nombre" className="form-control" placeholder={this.state.ingrese + "Nombre" + " *"} required="required" data-error={this.state.require + "Nombre" + " *"} value={this.state.name} onChange={this.handleChangeName} ></input>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">


                      <label htmlFor="form_name">Apellido *</label>
                      <input id="form_name" type="text" name="apellido" className="form-control" placeholder={this.state.ingrese + "Apellido" + " *"} required="required" data-error={this.state.require + "Apellido" + " *"} value={this.state.surname} onChange={this.handleChangeSurname}></input>


                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">




                      <label htmlFor="form_email">Email *</label>
                      <input id="form_email" type="email" name="email" className="form-control" placeholder={this.state.ingrese + "Email" + " *"} required="required" data-error={this.state.require + "Email" + " *"} value={this.state.email} onChange={this.handleChangeEmail}></input>


                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">

                      <label htmlFor="form_phone">Telefono *</label>
                      <input id="form_phone" type="tel" name="phone" className="form-control" placeholder={this.state.ingrese + "Telefono" + " *"} required="required" data-error={this.state.require + "Telefono" + " *"} value={this.state.phone} onChange={this.handleChangePhone}></input>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">



                      <label htmlFor="form_message">Consulta *</label>
                      <textarea id="form_message" name="message" className="form-control" placeholder={"Ingrese la " + "Consulta" + " que desea hacer *"} required="required" data-error={"Para continuar debes ingresar una " + "Consulta" + " *"} rows="4" required value={this.state.message} onChange={this.handleChangeMessage}></textarea>




                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <input type="button" className="btn btn-success btn-send" value="Enviar solicitud" onClick={this.handleSubmit}></input>
                  </div>
                </div>

              </div>

            </form>

          </div>

        </div>
      </div>
    );
  }
}