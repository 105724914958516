import React from 'react';

import { Link } from "react-router-dom";
import styled from "styled-components";
import _uniqueId from 'lodash/uniqueId';




const Styles = styled.div`
  .carousel-inner {
    background-color: black;
  }
  .carousel-inner img {
    width: 100%;
    height: 100%;
  }

  .carousel-inner img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  .carousel-inner img {
    position: relative;
    overflow: hidden;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    height: 25%;
    max-height: 400px;
  }
  .carousel-caption {
    padding-top: 50px;
  }
`;

export default class Home extends React.Component {

  state = {
    id: _uniqueId('prefix-'),
    products: [
      {
        id: 1,
        title: "Google Assistant",
        shortDesc: "Hey Google, talk like a Legend",
        image: "https://loremflickr.com/320/240",
        date: "Apr 3, 2019"
      },
      {
        id: 2,
        title: "Google Cloud",
        shortDesc: "The cloud demystified: How it works and why it matters",
        image: "https://loremflickr.com/320/240",
        date: "Apr 8, 2019"
      },
      {
        id: 3,
        title: "Google Assistant",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "https://loremflickr.com/320/240",
        date: "Apr 5, 2019"
      }
    ]
  };

  render() {
    return (
      <Styles>
        <div id="demo" className="carousel slide" data-ride="carousel">
          <ul className="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" className="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
          </ul>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/images/ventanas-horizontales-7.jpg"
                alt="Ventanas Horizontales"
                width="1100"
                height="500"
              ></img>
              <div className="carousel-caption">
                <h3>Ventanas Horizontales</h3>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/ventanas-horizontales-2.jpg"
                alt="Ventanas Horizontales"
                width="1100"
                height="500"
              ></img>
              <div className="carousel-caption">
                <h3>Ventanas Horizontales</h3>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/ventanas-horizontales.jpg"
                alt="Ventanas Horizontales"
                width="1100"
                height="500"
              ></img>
              <div className="carousel-caption">
                <h3>Ventanas Horizontales</h3>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#demo" data-slide="prev">
            <span className="carousel-control-prev-icon"></span>
          </a>
          <a className="carousel-control-next" href="#demo" data-slide="next">
            <span className="carousel-control-next-icon"></span>
          </a>
        </div>
      </Styles>

    );
  }
}
