import React from "react";
import styled from "styled-components";
import contactData from "../data/data.json";

const Styles = styled.div`
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .my-float {
    margin-top: 16px;
  }
`;

export default class Home extends React.Component {
  state = {
    tonumber: "5491125507046",
    message:
      "Hola,%20Buenas%20tardes,%0aquer%C3%ADa%20saber%20mas%20acerca%20de%20esta%20pagina"
  };

  render() {
    return (
      <Styles>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
        ></link>
        <a
          href={"https://api.whatsapp.com/send?phone="+ contactData.contact.number +"&text=Hola,%20Buenas%20tardes,%0aquer%C3%ADa%20saber%20mas%20acerca%20de%20esta%20pagina."}
          className="float"
          target="_blank"
        >
          <i className="fa fa-whatsapp my-float"></i>
        </a>
      </Styles>
    );
  }
}
