import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedin,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";



import {
  faEnvelope,
  faPhone
} from "@fortawesome/free-solid-svg-icons";


import "bootstrap";
/*
import "bootstrap/scss/bootstrap.scss";
*/
import "./styles.scss";

/* Pages */
import Home from "./pages/Home";
import Inicio from "./pages/Inicio";
import AboutUs from "./pages/AboutUs";
import Budget from "./pages/Budget";
import Contact from "./pages/Contact";

/* Components */
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WhatsappButton from "./components/WhatsappButton";

import Topbar from "./components/Topbar";

library.add(
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedin,
  faWhatsapp,
  faEnvelope,
  faPhone
);

function App() {
  return (
    <div>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Inicio} />
        <Route exact path="/inicio" component={Inicio} />
        <Route exact path="/quienessomos" component={AboutUs} />
        <Route exact path="/solicitarpresupuesto" component={Budget} />
        {/*
        <Route exact path="/inicio" component={Home} />
        */}
      </Switch>
      <WhatsappButton />
      <Footer />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  rootElement
);
