import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Styles = styled.div`
   .jumbotron {
      background: none;
      padding-bottom: 0;
   }

   .banner {
      .caption {
         p {
            font-size: 32px;
         }
      }
   }

   .jumbo-image-section {
      .img-block {
         height: 400px;
         background-repeat: no-repeat;
         background-size: cover;
         background-position: 100% auto;
      }
   }

   @media screen and (max-width: 767px) {
      .banner {
         .caption {
            p {
               font-size: 24px;
            }
         }
      }
   }

   @media screen and (min-width: 768px) {
      .jumbo-image-section {
         position: relative;

         .caption {
            position: absolute;
            top: 30%;
            left: 5%;
            width: 40%;
            z-index: 2;
         }
      }

      .our-stories,
      .careers,
      .locations {
         color: #fff;

         a {
            color: #fff;
         }
      }

      .careers,
      .locations {
         a {
            font-size: 1.2rem;
         }
      }

      .careers {
         .overlay {
            width: calc(100% - 0.5rem);
         }
      }

      .locations {
         .overlay {
            width: calc(100% - 0.5rem);
            left: auto;
            right: 0;
         }
      }

      .overlay {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: rgba(0, 0, 0, 0.3);
         z-index: 1;
         transition: 0.3s;

         &:hover {
            opacity: 0;
            visibility: hidden;
         }
      }
   }
`;

export default class Home extends React.Component {
   state = {
      products: [
         {
            id: 1,
            title: "Google Assistant",
            shortDesc: "Hey Google, talk like a Legend",
            image: "/images/products/product-1.jpg",
            date: "Apr 3, 2019"
         },
         {
            id: 2,
            title: "Google Cloud",
            shortDesc: "The cloud demystified: How it works and why it matters",
            image: "/images/products/product-2.jpg",
            date: "Apr 8, 2019"
         },
         {
            id: 3,
            title: "Google Assistant",
            shortDesc:
               "New types of answers from your Google Assistant on Android",
            image: "/images/products/product-3.jpg",
            date: "Apr 5, 2019"
         }
      ]
   };

   render() {
      return (
         <Styles>
            <div className="container">
               {/* Section */}
               <div className="section">
                  <div className="jumbotron banner">
                     <div className="row align-items-center">
                        <div className="col-12 col-md-5 order-1 order-md-0 text-center text-md-left">
                           <div className="caption">
                              <p className="display-4">
                                 Radically helpful things.
                              </p>
                           </div>
                        </div>
                        <div className="col-12 col-md-7">
                           <img src="/images/banner.jpg" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
               {/* Section */}
               <div className="section text-center my-5">
                  <h2>Our Mission</h2>
                  <p className="lead mt-4">
                     To organise the world’s information and make it universally
                     accessible and useful.
                  </p>
               </div>
               {/* Section */}
               <div className="section">
                  <h3 className="text-center mb-5">Product News</h3>
                  <div className="row">
                     {this.state.products.map(product => (
                        <div className="col-12 col-md-4 d-flex mb-3">
                           <div className="card" key={product.id}>
                              <img
                                 src={product.image}
                                 alt=""
                                 className="card-img-top"
                              />
                              <div className="card-body">
                                 <h6 className="card-title text-uppercase">
                                    {product.title}
                                 </h6>
                                 <p className="text-muted">
                                    {product.shortDesc}
                                 </p>
                              </div>
                              <div className="card-footer bg-white border-0">
                                 <small>{product.date}</small>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
               {/* Section */}
               <div className="section jumbo-image-section mt-3">
                  <div className="row align-items-center no-gutters">
                     <div className="col-md-12">
                        <img src="/images/product.jpg" alt="" />
                        <div className="caption text-center text-md-left mt-4 mt-md-0">
                           <h5 className="text-uppercase mb-3">Our Products</h5>
                           <h5 className="mb-md-5 mb-3">
                              Hi! How can we help?
                           </h5>
                           <p className="font-weight-bold">
                              <Link to="/">See what we build</Link>
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
               {/* Section */}
               <div className="section jumbo-image-section our-stories mt-4 mb-5">
                  <div className="row align-items-center no-gutters">
                     <div className="col-md-12">
                        <img src="/images/story.jpg" alt="" />
                        <div className="caption text-center text-md-left mt-4 mt-md-0">
                           <h5 className="text-uppercase mb-3">Our Stories</h5>
                           <h5 className="mb-md-5 mb-3">
                              The best part about technology is seeing what the
                              world does with it
                           </h5>
                           <p className="font-weight-bold">
                              <Link to="/">Explore our stories</Link>
                           </p>
                        </div>
                        <div className="overlay" />
                     </div>
                  </div>
               </div>
               {/* Section */}
               <div className="section jumbo-image-section mt-4 mb-5">
                  <div className="row align-items-center no-gutters">
                     <div className="col-md-6 careers mb-3 mb-md-0 pr-md-2">
                        <div
                           className="img-block"
                           style={{
                              backgroundImage: "url('/images/careers.jpg')"
                           }}
                        />
                        <div
                           className="caption w-100 ml-md-2 text-center text-md-left mt-4 mt-md-0"
                           style={{ top: "10%" }}
                        >
                           <h4 className="mb-3">Careers at Google</h4>
                           <p className="mb-md-5 mb-3 font-weight-bold">
                              <Link to="/">Join us</Link>
                           </p>
                        </div>
                        <div className="overlay" />
                     </div>
                     <div className="col-md-6 locations pl-md-2">
                        <div
                           className="img-block"
                           style={{
                              backgroundImage: "url('/images/location.jpg')"
                           }}
                        />
                        <div
                           className="caption w-100 ml-md-2 text-center text-md-left mt-4 mt-md-0"
                           style={{ top: "10%" }}
                        >
                           <h4 className="mb-3">Google locations</h4>
                           <p className="mb-md-5 mb-3 font-weight-bold">
                              <Link to="/">Find us</Link>
                           </p>
                        </div>
                        <div className="overlay" />
                     </div>
                  </div>
               </div>
            </div>
         </Styles>
      );
   }
}
