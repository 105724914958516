import React from 'react';

import { Link } from "react-router-dom";
import styled from "styled-components";
import _uniqueId from 'lodash/uniqueId';
import CarruselJS from "./../components/CarruselJS";
import CarruselJS2 from "./../components/CarruselJS2";
import contactData from "../data/data.json";


const Styles = styled.div`
.carousel-inner {
  background-color: #5F81E3 !important;
  }


.carousel-item> img{
  background: -prefix-linear-gradient(top, blue, white);
  
  background-image: linear-gradient(
    0deg
    ,#000,rgba(0,0,0,.8) 25%,rgba(0,0,0,.6) 50%,rgba(0,0,0,.4) 75%,rgba(0,0,0,.2));
}

.captionh3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  -webkit-text-stroke: 1.5px black;
  -webkit-text-fill-color: white;
  font-size: 2.25em;
  /*
  font-size: 4em;
  -webkit-animation: fill 0.5s infinite alternate;
  */
}


.carousel-caption{
  /*
  text-shadow: 3px 3px 3px #000;
  -webkit-text-stroke: 1px black;



  color: white;
  text-shadow:
      3px 3px 0 #000,
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000;



      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;



      color: white;
      -webkit-font-smoothing: antialiased;



      
      -webkit-text-stroke:5px black;
      -webkit-text-fill-color:transparent;




      color:white;
      text-shadow:1px 0 0 black,0 1px 0 black,-1px 0 0 black,0 -1px 0 black;
    
    
      font-size:70px;
      text-align:center;
      text-anchor:middle;
      stroke:dodgerblue;
      stroke-width:3px;
      fill:none;

      color: white;
      text-shadow:
          3px 3px 0 #000,
        -1px -1px 0 #000,  
         1px -1px 0 #000,
         -1px 1px 0 #000,
          1px 1px 0 #000;
  */

      color:white;
      text-shadow:1px 0 0 black,0 1px 0 black,-1px 0 0 black,0 -1px 0 black;

      
}





/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.carousel-item:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; 
  height:100%;
  display:inline-block;
  background-image: linear-gradient(
    0deg,rgba(0,0,0,.3),rgba(0,0,0,.3) 15%,rgba(0,0,0,.2) 50%,rgba(0,0,0,.1) 75%,rgba(0,0,0,.05));
}
.carousel-item img{
  /*
  display:block;
  */
}


`;

export default class Carruseles extends React.Component {

  state = {
    caruseles: contactData.carrouseles,
    FotosAberturas: [
      {
        id: 1,
        title: "Google Assistant",
        shortDesc: "Hey Google, talk like a Legend",
        image: "/images/products/product-1.jpg",
        date: "Apr 3, 2019"
      },
      {
        id: 2,
        title: "Google Cloud",
        shortDesc: "The cloud demystified: How it works and why it matters",
        image: "/images/products/product-2.jpg",
        date: "Apr 8, 2019"
      },
      {
        id: 3,
        title: "Google Assistant",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "/images/products/product-3.jpg",
        date: "Apr 5, 2019"
      },
      {
        id: 4,
        title: "Google Assistant",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "/images/products/product-3.jpg",
        date: "Apr 5, 2019"
      },
      {
        id: 5,
        title: "Google Assistant",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "/images/products/product-3.jpg",
        date: "Apr 5, 2019"
      }
    ],
    FotosAberturas2: [
      {
        id: 1,
        title: "Escaleras",
        shortDesc: "Demo 1",
        image: "/images/ig/164743992_519827426074728_41879992773731036_n.jpg",
        date: "Apr 3, 2019"
      },
      {
        id: 2,
        title: "Portones",
        shortDesc: "The cloud demystified: How it works and why it matters",
        image: "/images/ig/164540831_158707156107551_6862567439589142484_n.jpg",
        date: "Apr 8, 2019"
      },
      {
        id: 3,
        title: "Techos",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "/images/ig/170617238_131055588989729_4989432873680624674_n.jpg",
        date: "Apr 5, 2019"
      },
      {
        id: 4,
        title: "Barandas",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "/images/ig/197770083_169391651805967_4620884820276630931_n.jpg",
        date: "Apr 5, 2019"
      },
      {
        id: 5,
        title: "Estructuras",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "/images/ig/222650403_938351543685176_5255636278741752126_n.jpg",
        date: "Apr 5, 2019"
      },
      {
        id: 6,
        title: "Rejas",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "/images/ig/188620865_463101378330223_7356356938432081081_n.jpg",
        date: "Apr 5, 2019"
      }
    ],
    FotosRejas: [
      {
        id: 1,
        title: "Separador de ambientes",
        shortDesc: "Demo 1",
        image: "/images/ig/224535570_260364838829281_5147341107146464587_n.jpg",
        date: "Apr 3, 2019"
      },
      {
        id: 2,
        title: "Portón de entrada",
        shortDesc: "Demo 1",
        image: "/images/ig/189644050_231772538717464_3278592389242362627_n.jpg",
        date: "Apr 3, 2019"
      },
      {
        id: 3,
        title: "Techos y pergolas",
        shortDesc: "Demo 1",
        image: "/images/ig/189644050_231772538717464_3278592389242362627_n.jpg",
        date: "Apr 3, 2019"
      },
      {
        id: 4,
        title: "Frente de rejas decorativas",
        shortDesc: "Demo 1",
        image: "/images/ig/210232943_227784005703997_8675564814695446309_n.jpg",
        date: "Apr 3, 2019"
      },

    ]
  };




  render() {


    return (
      <Styles>
        {/*
        <CarruselJS items={this.state.FotosAberturas2}></CarruselJS>
        <CarruselJS items={this.state.FotosRejas}></CarruselJS>
        <CarruselJS2 items={this.state.FotosRejas}></CarruselJS2>
        */}
        <div className="container">


        <div className="row">
        </div>
        {contactData.carrouseles.map( element => (
          <CarruselJS2 className="col-8" key={_uniqueId('carrusel-')} items={element.fotos} title={element.nombre}></CarruselJS2>
        ))}
        </div>



        {/*
        <CarruselJS2 items={contactData.carrouseles.utr.fotos} title={contactData.carrouseles.utr.nombre}></CarruselJS2>
        <CarruselJS2 items={contactData.carrouseles.apv.fotos} title={contactData.carrouseles.apv.nombre}></CarruselJS2>
        <CarruselJS2 items={contactData.carrouseles.g1.fotos} title={contactData.carrouseles.g1.nombre}></CarruselJS2>
        <CarruselJS2 items={contactData.carrouseles.g2.fotos} title={contactData.carrouseles.g2.nombre}></CarruselJS2>
        */}



      </Styles>
    );
  }
}
