import React from 'react';

import { Link } from "react-router-dom";
import styled from "styled-components";
import _uniqueId from 'lodash/uniqueId';




const Styles = styled.div`
  .carousel-inner {
    background-color: black;
  }
  .carousel-inner img {
    width: 100%;
    height: 100%;
  }

  .carousel-inner img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  .carousel-inner img {
    position: relative;
    overflow: hidden;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    height: 25%;
    max-height: 400px;
  }
  .carousel-caption {
    padding-top: 50px;
  }
`;

export default class CarruselBeta extends React.Component {

  state = {
    id: _uniqueId('prefix-'),
    products: [
      {
        id: 1,
        title: "uno",
        shortDesc: "Hey Google, talk like a Legend",
        image: "https://loremflickr.com/320/240",
        date: "Apr 3, 2019"
      },
      {
        id: 2,
        title: "dos",
        shortDesc: "The cloud demystified: How it works and why it matters",
        image: "https://loremflickr.com/320/240",
        date: "Apr 8, 2019"
      },
      {
        id: 3,
        title: "tres",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "https://loremflickr.com/320/240",
        date: "Apr 5, 2019"
      },
      {
        id: 4,
        title: "cuatro",
        shortDesc: "New types of answers from your Google Assistant on Android",
        image: "https://loremflickr.com/320/240",
        date: "Apr 5, 2019"
      }
    ]
  };




  render() {




    return (
      <Styles>
        <div id={this.state.id} className="carousel slide" data-ride="carousel">
          {/*this.createCarouselIndicators
              <div key={"li-"+product.id} className="carousel-item">
              
              
              */}
          <ul className="carousel-indicators">{this.state.products.map(product => (
              <li key={product.title} data-target={"#" + this.state.id} data-slide-to={product.id} className={(product.id == 1 ? 'active' : '')}></li>
            ))}</ul>

          <div className="carousel-inner">{this.state.products.map(product => (
              <div key={product.title} className={"carousel-item " + (product.id == 1 ? 'active' : '')}>
                <img
                  src={product.image}
                  alt={product.title}
                  width="1100"
                  height="500"
                ></img>
                <div className="carousel-caption">
                  <h3>{product.title}</h3>
                </div>
              </div>
            ))}</div>

          <a className="carousel-control-prev" href={"#" + this.state.id} data-slide="prev">
            <span className="carousel-control-prev-icon"></span>
          </a>
          <a className="carousel-control-next" href={"#" + this.state.id} data-slide="next">
            <span className="carousel-control-next-icon"></span>
          </a>
        </div>
      </Styles>
    );
  }
}
