
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import contactData from "../data/data.json";

const Styles = styled.div`
`;

export default class AboutUs extends React.Component {
    state = {};

    render() {
        return (
            <div>
                <section className="about-us py-5 " id="about-us">
                    <div className="container mt-5">
                        <div className="row">
                            
                        <div className="col-md-6 pb-5">
                                <img src="/images/Diseño_1.jpg " alt=""></img>
                            </div>
                            <div className="col-md-6">
                                <h1 className='text-success'>Quienes Somos?</h1>
                                <h3>Conoce un poco más acerca de nosotros</h3>
                                <hr></hr>
                                <p>Somos un equipo comprometido en la fabricación y diseño de cada proyecto solicitado por el cliente, priorizando sus preferencias y/o funcionalidades y brindando soluciones a medida. 
                                </p>
                                <p>Nos basamos en los pilares fundamentales para que los mismos sean ejecutados con éxito: conocimiento, compromiso, responsabilidad y creatividad. Es por ello, que proponemos una amplia variedad de productos y/o trabajos finales, desde aberturas, cerramientos, canchas de paddle, hasta naves industriales de amplias luces. Estamos convencidos, que donde existe un proyecto, existe un futuro.</p>


                                <a className="btn btn-success" href={"https://www.instagram.com/"+contactData.contact.instagram} role="button">Ver nuestros ultimos trabajos</a>

                            </div>
                        </div>
                    </div>
                </section>
                {/**/}
            </div>

        );
    }
}




