
import _uniqueId from 'lodash/uniqueId';

import "./styles/styles.css"


export default function CarruselJS2(props) {
  var myId = _uniqueId('prefix-')

  return (
    <div>
      
      <h3 className="pl-1 text-capitalize pb-2 pt-3">{props.title}</h3>


      <div id={myId} className="carousel slide" data-ride="carousel">
          <ul className="carousel-indicators">{props.items.map(product => (
              <li key={product.id +"keyindi"+ _uniqueId() } data-target={"#" + myId} data-slide-to={product.id} className={(product.id == 1 ? 'active' : '')}></li>
            ))}</ul>

          <div className="carousel-inner ">{props.items.map(product => (
              <div key={product.id +"keyinner" + _uniqueId()} className={"carousel-item " + (product.id == 1 ? 'active' : '')}>
                <img
                  src={product.image}
                  alt={product.title}
                ></img>
                <div className="carousel-caption">
                  <h3 className="captionh3 text-capitalize">{product.title}</h3>
                </div>
              </div>
            ))}</div>

          <a className="carousel-control-prev" href={"#" + myId} data-slide="prev">
            <span className="carousel-control-prev-icon"></span>
          </a>
          <a className="carousel-control-next" href={"#" + myId} data-slide="next">
            <span className="carousel-control-next-icon"></span>
          </a>
        </div>
    </div>
  )
}
  
CarruselJS2.defaultProps = {
  title: "Galeria",
  name: "Rahul",
  eyeColor: "deepblue",
  age: "45",
  items: [
    {
      id: 1,
      title: "uno",
      shortDesc: "Hey Google, talk like a Legend",
      image: "https://loremflickr.com/320/240",
      date: "Apr 3, 2019"
    },
    {
      id: 2,
      title: "dos",
      shortDesc: "The cloud demystified: How it works and why it matters",
      image: "https://loremflickr.com/320/240",
      date: "Apr 8, 2019"
    },
    {
      id: 3,
      title: "tres",
      shortDesc: "New types of answers from your Google Assistant on Android",
      image: "https://loremflickr.com/320/240",
      date: "Apr 5, 2019"
    },
    {
      id: 4,
      title: "cuatro",
      shortDesc: "New types of answers from your Google Assistant on Android",
      image: "https://loremflickr.com/320/240",
      date: "Apr 5, 2019"
    }
  ]
};
