import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Carrusel from "./../components/Carrusel";
import CarruselBeta from "./../components/Carrusel.beta";
import CarruselAlpha from "./../components/Carrusel.alpha";
import CarruselJS from "./../components/CarruselJS";
import Carruseles from "./../components/Carruseles";

const Styles = styled.div`
  .carousel-inner {
    background-color: black;
  }
  .carousel-inner img {
    width: 100%;
    height: 100%;
  }

  .carousel-inner img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  .carousel-inner img {
    position: relative;
    overflow: hidden;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    height: 25%;
    max-height: 400px;
  }
  .carousel-caption {
    padding-top: 50px;
  }
`;

export default class Inicio extends React.Component {
  state = {};

  render() {
    return (
      <div className="pt-3">
          <Carruseles className="content-car"></Carruseles>
          {/**/}
      </div>

    );
  }
}
