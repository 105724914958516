import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Topbar from './Topbar';

const Styles = styled.div`
  .navbar-brand {
    text-transform: uppercase;
  }
`;

const Navbar = () => (
  <Styles>
      {/*<!-- Links
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" href="#">Link 1</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Link 2</a>
    </li>
  </ul>
  -->
  <!-- Navbar text-->
  
  
  
  
  
    <nav className="navbarinfo navbar navbar-expand-sm py-1 bg-dark navbar-dark">
      <span className="navbar-text">tel: +54 9 11 2550 7046</span>
    </nav>
    
    */}
    <Topbar></Topbar>




    <nav className="navbar navbar-expand-sm navbar-light bg-light navbar sticky-top">
      <div className="container">
        <NavLink to="/" className="navbar-brand pt-0 pb-0">
          {/*Innova A&E
          Innova A{"&"}E
          */}
          <img src="/images/innova.jpeg" width="100" alt="" className="d-inline-block align-middle mr-2"></img>

        </NavLink>
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbar"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav ml-auto">
            <li className="navbar-item">
              <NavLink to="/quienessomos" className="nav-link">
                Quienes Somos
              </NavLink>
            </li>
            {/*
            <li className="navbar-item">
              <NavLink to="/" className="nav-link">
                Productos
              </NavLink>
            </li>
            */}
            {/*
            <li className="navbar-item">
              <NavLink to="/contacto" className="nav-link">
                Contacto
              </NavLink>
            </li>
            */}
            <li className="navbar-item">
              <NavLink to="/solicitarpresupuesto" className="nav-link">
                <strong>Solicitar presupuesto</strong>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </Styles>
);

export default Navbar;
