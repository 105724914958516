
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import contactData from "../data/data.json";

const Styles = styled.div`
`;

export default class Contact extends React.Component {
    state = {};

    render() {
        return (
            <div>
                <section className="about-us py-5 " id="about-us">
                    <div className="container mt-5">
                        <div class="row">
                            <div class="col-md-12 wow animated fadeInLeft" data-wow-delay=".2s">
                                <h1 class="section-title">Contactanos</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-3 contact-widget-section2 wow animated fadeInLeft" data-wow-delay=".2s">
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content.</p>

                                <div class="find-widget">
                                    Company: <a href="https://hostriver.ro">HostRiver</a>
                                </div>
                                <div class="find-widget">
                                    Address: <a href="#">4435 Berkshire Circle Knoxville</a>
                                </div>
                                <div class="find-widget">
                                    Phone: <a href="#">+ 879-890-9767</a>
                                </div>

                                <div class="find-widget">
                                    Website: <a href="https://uny.ro">www.uny.ro</a>
                                </div>
                                <div class="find-widget">
                                    Program: <a href="#">Mon to Sat: 09:30 AM - 10.30 PM</a>
                                </div>
                            </div>
                            <div class="col-md-6 wow animated fadeInRight" data-wow-delay=".2s">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.747627260441!2d-58.38913445365461!3d-34.78712815605316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcd323624b4bd1%3A0x915c70cb1c9e4c4e!2sAv.%20Almte.%20Brown%204398%2C%20Temperley%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1630774872976!5m2!1ses-419!2sar" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>

                    </div>
                </section>
                {/**/}
            </div>

        );
    }
}




