import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import contactData from "../data/data.json";



const Styles = styled.div`


.navbar {
  width: 100%;
  background-color: #333;
  overflow: auto;
  /*
  font-family: Arial, Helvetica, sans-serif;
  */
}

.navbar a {
    width: 25%; /* Four links of equal widths */
    

  float: left;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 12px;

  
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
}

.navbar a.active {
    background-color: #04AA6D;
    color: white;
}

@media screen and (max-width: 500px) {
  .navbar a {
    float: none;
    display: block;
    width: 100%;
    text-align: left;
  }
}
`;

const Navbar = () => (
    <Styles>
        {/*
        <nav class="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
            <ul class="nav justify-content-around">
                <li class="nav-item">
                    <a class="navbar-text" href="#">+549 (011) 2550 7046</a>
                </li>
                <li class="nav-item">
                    <a class="navbar-text" href="#">Aranguren 2976, Ciudad de Buenos Aires</a>
                </li>
                <li class="nav-item">
                    <a class="navbar-text" href="#">luca.sain@outlook.com</a>
                </li>
                <li class="nav-item">
                    <a class="navbar-text disabled" href="#">Disabled</a>
                </li>
            </ul>

        </nav>
        
        
        <h2>Responsive Navbar with Links of Same Width</h2>
        <p>Try to resize the browser window to see the responsive effect.</p>
        */}
        {/*
        https://www.w3schools.com/howto/howto_css_topnav_equal_width.asp
        https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_topnav_equal_width_icons

        https://www.w3schools.com/howto/howto_css_topnav_centered.asp
        */}

        <div className="navbar">
            <a href="#"><i className="fa fa-fw fa-phone"></i> {contactData.contact.numberStyled}</a>
            <a href="#"><i className="fa fa-fw fa-map"></i>{contactData.contact.location}</a>
            <a href="#"><i className="fa fa-fw fa-envelope"></i>{contactData.contact.email}</a>
        </div>
    </Styles>
);

export default Navbar;
